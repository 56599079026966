<template>
  <v-card :loading="loading" :disabled="loading" class="mt-n10 moholand-shadow">
    <v-card-title>
      {{ 'ویرایش "' + title + '"' }}
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />

        <v-row align="center" justify="end" class="mb-3">
          <v-btn
            class="mx-4"
            dark
            color="success"
            type="submit"
            :loading="loading"
          >
            ویرایش نرم افزار
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import VFormBase from "vuetify-form-base";
import { getErrorText } from "@/constants/GlobalConstants";
import { formPostPrepare, formGetPrepare } from "@/constants/PanelFormHandler";

export default {
  components: {
    VFormBase,
  },
  created() {
    this.getData();
  },

  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      title: "",
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("edit") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      model: {
        author_id: this.$store.state?.userData?.id || 0,
        name: "",
        format: "",
        color: "#FF0000",
      },
      schema: {
        name: {
          type: "CustomInput",
          label: "نام فارسی",
          rules: [Constants.rules.required, Constants.rules.persian_string],
          required: true,
        },
        format: {
          type: "CustomInput",
          label: "پسوند",
          class: "ltr",
          prefix: ".",
          hint: "فرمت را بدون نقطه وارد نمایید",
          persistentHint: true,
          rules: [Constants.rules.required, Constants.rules.english_string],
          required: true,
        },
        color: {
          type: "CustomInput",
          inputType: "colorpicker",
          label: "رنگ",
          rules: [Constants.rules.required],
          required: true,
          col: { cols: 12 },
        },
      },
    };
  },

  methods: {
    getData() {
      this.loading = true;
      MyAxios.get("/software/index/" + this.id, {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.title = response.data[0].name;
          formGetPrepare(response, this.model);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = formPostPrepare(this.model);
        data.append("slug", this.model.name.replace(/\/|\s|\./g, "-"));

        if (this.model.color?.length > 7)
          data.append("color", this.model.color.substring(0, 7));

        MyAxios.post(`software/${this.id}/update`, data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "بانک با موفقیت ویرایش شد",
              type: "success",
            });
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: getErrorText(error),
            });
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
  },
};
</script>

<style></style>
